import React from "react";
import { Route, HashRouter as Router, Routes } from "react-router-dom";

import Home from "./pages/LandingPage.tsx";
import Gallery from "./pages/Gallery.tsx";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/gallery/" element={<Gallery />} />
      </Routes>
    </Router>
  );
}

export default App;
