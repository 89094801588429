import React from "react";
import Navbar from "../components/Navbar.tsx";
import "./LandingPage.css";

const LandingPage = () => {
  const backgroundImage = require("../assets/griffith.jpg");

  return (
    <div className="landing-container">
      <Navbar />
      <div
        className="hero-section"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="hero-content">
          <h1>Colin Pope</h1>
          <div className="contact-info">
            <p>Email: cpope483@gmail.com</p>
            <p>Phone: (707) 344-1501</p>
          </div>
          <div className="description">
            <p>
              I'm Colin, a photographer based in the Central Coast of
              California. I specialize in landscape and portrait photography,
              but have branched out to other styles as well. I'm always looking
              for new opportunities to grow and learn, so feel free to reach out
              to me!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
