import React from "react";
import "./Navbar.css";

const Navbar = () => {
  return (
    <div className="navbar">
      <nav className="navbar-nav">
        <ul>
          <li>
            <h2>Colin Pope</h2>
          </li>
          <li>
            <a href="/#/">Home</a>
          </li>
          <li>
            <a href="/#/gallery/">Gallery</a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
