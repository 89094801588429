import React from "react";
import Navbar from "../components/Navbar.tsx";
import Photo from "../components/Photo.tsx";
import "./Gallery.css";

const Gallery = () => {
  const images = [
    { img: require("../assets/blake.jpg"), alt: "Blake", desc: "Blake" },
    { img: require("../assets/ryan.jpg"), alt: "Ryan", desc: "Ryan" },
    { img: require("../assets/taxi.jpg"), alt: "Taxi", desc: "Taxi" },
    {
      img: require("../assets/waterfall.jpg"),
      alt: "Waterfall",
      desc: "Waterfall",
    },
    {
      img: require("../assets/griffith.jpg"),
      alt: "Griffith",
      desc: "Griffith",
    },
    {
      img: require("../assets/griffith-2.jpg"),
      alt: "Griffith 2",
      desc: "Griffith 2",
    },
    {
      img: require("../assets/hollywood.jpg"),
      alt: "Hollywood",
      desc: "Hollywood",
    },
    { img: require("../assets/boats.jpg"), alt: "Boats", desc: "Boats" },
    { img: require("../assets/canessa.jpg"), alt: "Canessa", desc: "Canessa" },
    {
      img: require("../assets/ghirardelli.jpg"),
      alt: "Ghirardelli",
      desc: "Ghirardelli",
    },
    {
      img: require("../assets/blake-2.jpg"),
      alt: "Blake 2",
      desc: "Blake 2",
    },
    {
      img: require("../assets/ryan-2.jpg"),
      alt: "Ryan 2",
      desc: "Ryan 2",
    },
    {
      img: require("../assets/tree.jpg"),
      alt: "Tree",
      desc: "Tree",
    },
  ];

  return (
    <div className="home-container">
      <Navbar />
      <div className="photo-grid">
        {images.map((i) => (
          <Photo img={i.img} alt={i.alt} desc={i.desc} />
        ))}
      </div>
    </div>
  );
};

export default Gallery;
