import React from "react";
import "./Photo.css";

const Photo = ({ img, alt, desc }: { img: any; alt: string; desc: string }) => {
  return (
    <div className="photo-container">
      <img src={img} alt={alt} className="photo" />
      <h3>{desc}</h3>
    </div>
  );
};

export default Photo;
